import {
	find, filter, isEmpty, isBlank, sumOf, isNothing
}
from 'voUtils/tools.js';

function getOriginalTotal(quote, section, addDeposit) {
	var sum = 0;
	if (quote) {
		if (section === 'all') {
			sum = sumOf(quote.mandatory, function (l) {
				return isNothing(l.oTotal) ? l.total : l.oTotal;
			});
			sum += sumOf(quote.onsite, function (l) {
				return isNothing(l.oTotal) ? l.total : l.oTotal;
			});
		} else {
			sum = sumOf(quote[section || 'mandatory'], function (l) {
				return isNothing(l.oTotal) ? l.total : l.oTotal;
			});
		}

		if (quote.deposit && (addDeposit === quote.deposit.location || addDeposit === true)) {
			sum += quote.deposit.amount;
		}


	}

	return sum;

}

function getTotal(quote, section, addDeposit) {
	// 		V.log("section", quote, section);
	var sum = 0;
	if (quote) {
		if (section === 'all') {
			sum = sumOf(quote.mandatory, 'total');
			sum += sumOf(quote.onsite, 'total');
		} else {
			sum = sumOf(quote[section || 'mandatory'], 'total');
		}

		if (quote.deposit && (addDeposit === quote.deposit.location || addDeposit === true)) {
			sum += quote.deposit.amount;
		}
	}



	return sum;

}



function getDiscountName(quote) {
	if (quote && quote.mandatory) {
		let names = new Set();

		quote.mandatory.forEach(sl => {
			if (!isBlank(sl.discountName)) {
				names.add(sl.discountName);
			}
		});
		if (names.size) {
			return Array.from(names).join(', ');
		}

	}
	return '';

}

function canAddLine(sl, quote) {

	var maxAmount;
	var blocked;
	if (quote && quote.serviceLimits && quote.serviceLimits[sl.service.id]) {
		blocked = quote.serviceLimits[sl.service.id].blockedByDate;
		maxAmount = quote.serviceLimits[sl.service.id].maxPerBooking;
	} else if (VOFFICE.maxAmounts && VOFFICE.maxAmounts[sl.service.id]) {
		//DEPRECATED old way of limiting amount
		maxAmount = VOFFICE.maxAmounts[sl.service.id];
	}

	return (sl.optional || sl.onsiteOptional) && (maxAmount === undefined || maxAmount === null || maxAmount > sl.amount) && !blocked;
	//return (sl.optional || sl.onsiteOptional) && !(VOFFICE.maxAmounts && VOFFICE.maxAmounts[sl.service.id] <= sl.amount);
}

function canRemoveLine(sl) {
	return sl.booked;
}

function updateTotal(sl) {
	sl.total = sl.price * (sl.amount || 1) * (sl.timespan || 1);
	if (typeof sl.oPrice == 'number') {
		sl.oTotal = sl.oPrice * (sl.amount || 1) * (sl.timespan || 1);
	}

}

function addLine(quote, sl) {
	if (sl.optional || sl.onsiteOptional) {
		if (!sl.booked) {
			sl.booked = true;

			if (sl.onsiteOptional) {
				quote.onsiteOptional.splice(quote.onsiteOptional.indexOf(sl), 1);
				quote.onsite.push(sl);
			} else {
				quote.optional.splice(quote.optional.indexOf(sl), 1);
				quote.mandatory.push(sl);
			}
		}

		sl.amount = sl.amount ? sl.amount + 1 : 1;
		updateTotal(sl);
	}

}

function removeLine(quote, sl) {
	if (sl.booked) {
		if (sl.amount > 1) {
			sl.amount = sl.amount - 1;
		} else {
			sl.booked = false;
			sl.amount = null;

			if (sl.onsiteOptional) {
				quote.onsite.splice(quote.onsite.indexOf(sl), 1);
				quote.onsiteOptional.push(sl);
			} else {
				quote.mandatory.splice(quote.mandatory.indexOf(sl), 1);
				quote.optional.push(sl);
			}
		}
		updateTotal(sl);
	}

}


function getAgioAmount(amount, agioSetup) {
	if (agioSetup && amount && agioSetup.commission) {
		amount = Math.round(amount * 100 / (100 - (agioSetup.commission / 100)));
		if (agioSetup.flat) {
			amount += agioSetup.flat;
		}
	}
	return amount;
}



export default {
	getOriginalTotal,
	getTotal,
	getDiscountName,
	canAddLine,
	canRemoveLine,
	updateTotal,
	addLine,
	getAgioAmount,
	removeLine
};