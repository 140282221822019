var exp = {};

if (console && console.log && (VOFFICE.mode === 'dev' || VOFFICE.mode === 'preview')) {
	exp.log = console.log.bind(window.console);
} else {
	exp.log = function () {};
}

VOFFICE.enableLog = function () {
	exp.log = console.log.bind(window.console);
	return 'ok';
};


if (console && console.warn) {
	exp.logWarn = console.warn.bind(window.console);
} else {
	exp.logWarn = function () {};
}

if (console && console.error) {
	exp.logError = console.error.bind(window.console);
} else {
	exp.logError = function () {};
}

export default exp;