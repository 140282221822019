import V from 'voUtils/V.js';
import QuoteTools from 'voUtils/QuoteTools.js';

import {
	getStorage
}
from './StorageEx.js';
import {
	pick, omit, sumOf, isNothing, param, removeKey, isEmpty, oneIs, guid, each
}
from './tools.js';
import DateEx from 'voUtils/DateEx.js';

const lSettings = getStorage('cookies');
const lsUserInfo = getStorage('ui');

import {
	getAbTest
}
from 'voUtils/AbTest.js';




var ok = lSettings.getBool('ok');

V.log("cookies ok", isCookiesOk(), hasCookieChoice());

var head = document.getElementsByTagName('head')[0];

function updateScriptExecuted() {
	if (isCookiesOk()) {
		let el = document.getElementById('voffice-gtm-src');
		if (el) {
			var s = document.createElement('script');
			s.type = 'text/javascript';
			s.id = 'voffice-gtm-script';
			s.text = el.textContent;
			head.appendChild(s);
		}

	} else {
		let el = document.getElementById('voffice-gtm-script');
		if (el) {
			location.reload();
		}
	}

}

updateScriptExecuted();

function saveSelection(cookiesOk) {
	lSettings.setBool('ok', cookiesOk);
	ok = cookiesOk;
	updateScriptExecuted();
}

function hasCookieChoice() {
	return ok !== undefined;
}

function isCookiesOk() {
	return ok;
}



function pushDataLayer(eventname, data) {
	V.log("pushDataLayer", eventname, data);

	var ev = {
		'event': eventname,
		hp: {
			lang: VOFFICE.lang,
			domain: window.location.hostname
		}
	};
	if (getAbTest()) {
		ev.abtest = getAbTest();
	}

	if (typeof dataLayer !== 'undefined') {
		let ui = getUserInfo();
		if (ui) {
			ev.ui = ui;
		}

		if (data) {
			Object.assign(ev, data);
		}

		V.log("pushing google tag manager!", ev);

		dataLayer.push(ev);
	}


}


function fireCustomEvent(eventname) {
	pushDataLayer(eventname);

}



function fireSearch(results, search) {

	// 		V.log("fireSearch", results, search);

	var req = pick(search, 'filter', 'sorting');

	req.filter.adults = parseInt(req.filter.adults, 10) || 0;
	req.filter.children = parseInt(req.filter.children, 10) || 0;
	req.filter.babys = parseInt(req.filter.babys, 10) || 0;

	req.beds = req.filter.adults + req.filter.children;
	req.persons = req.filter.adults + " " + req.filter.children + " " + req.filter.babys;

	req.query = param(search.filter);
	req.results = results;

	req.hrental_pagetype = 'searchresults';

	pushDataLayer('searchResult', req);

}


function fireFormSubmit(data) {

	var req = pick(data, 'subject', 'unit');

	pushDataLayer('formSubmit', {
		data: req
	});

}

function fireQuote(data) {

	V.log("quote", data);

	var req = omit(data, 'quote');
	DateEx.encodeDates(req.booking);

	req.hrental_startdate = data.booking.from;
	req.hrental_enddate = data.booking.till;

	req.total = QuoteTools.getTotal(data.quote);
	req.gtm_total = req.total / 100;
	req.hrental_totalvalue = req.gtm_total;
	req.hrental_pagetype = 'offerdetail';
	req.hrental_id = data.unitId;

	pushDataLayer('quote', req);


}



function fireUnitAction(data) {
	var req = omit(data, 'quote');
	V.log("unitaction", data, req);
	DateEx.encodeDates(req.booking);
	req.total = QuoteTools.getTotal(data.quote);
	req.gtm_total = req.total / 100;
	req.hrental_totalvalue = req.gtm_total;

	pushDataLayer('unitAction', req);

}

function fireCheckout(data) {

	var req = omit(data, 'quote');
	DateEx.encodeDates(req.booking);
	req.total = QuoteTools.getTotal(data.quote);
	req.gtm_total = req.total / 100;
	req.hrental_totalvalue = req.gtm_total;

	req.hrental_startdate = req.booking.from;
	req.hrental_enddate = req.booking.till;
	req.hrental_id = data.unitId;

	req.hrental_pagetype = 'conversionintent';

	pushDataLayer('checkout', req);

}

function fireBooking(data, res) {

	var req = {
		action: data.action,
		booking: data.bookings[0] //omit(data.bookings[0], 'servicelines')
	};

	var lines = removeKey(req.booking, 'servicelines');
	/*
	req.booking.transactionProducts = collect(lines, function (sl) {
		return {
			'sku': sl.service.id,
			'name': sl.service.name,
			'price': sl.price / 100,
			'quantity': (sl.amount || 1) * (sl.timespan || 1)
		};

	});
	*/

	DateEx.encodeDates(req.booking);
	req.total = sumOf(lines, 'total');
	req.gtm_total = req.total / 100;

	req.hrental_totalvalue = req.gtm_total;
	req.hrental_pagetype = 'conversion';
	req.hrental_startdate = req.booking.from;
	req.hrental_enddate = req.booking.till;
	req.hrental_id = req.booking.unit;

	if (!isNothing(res.mr)) {
		req.mr = res.mr;
		req.gtm_mr = req.mr / 100;
	}

	req.id = res.reservation.id;
	req.nr = res.reservation.nr;



	V.log("fireBooking", data, res);

	pushDataLayer('booking', req);

	if (data.reservation && data.reservation.customer && data.reservation.customer.newsletter) {
		fireNewsletterSignup('booking');
	}

}

function fireNewsletterSignup(origin) {

	pushDataLayer('newsletterSignup', {
		origin: origin
	});

}



function fireOptionBooked(data, res) {


	var req = {
		booking: pick(data.bookings[0], 'adults', 'babys', 'children', 'fromdate', 'tilldate')
	};


	DateEx.encodeDates(req.booking);
	if (data.bookings[0] && data.bookings[0].lines) {
		req.total = sumOf(data.bookings[0].lines.mandatory, 'total');
		req.gtm_total = req.total / 100;
		req.hrental_totalvalue = req.gtm_total;
	}

	req.hrental_pagetype = 'conversion';
	req.hrental_startdate = req.booking.from;
	req.hrental_enddate = req.booking.till;
	req.hrental_id = data.unitId;


	if (!isNothing(res.mr)) {
		req.mr = res.mr;
		req.gtm_mr = req.mr / 100;
	}

	req.hrental_pagetype = 'conversion';


	pushDataLayer('optionBooked', req);

}


//load voffice channel, campaign etc.
var userInfo;

function getUserInfo() {
	return isEmpty(userInfo) ? null : userInfo;
}

function setUserInfo(k, v) {
	lsUserInfo.set(k, v);
	userInfo[k] = v;
}

function updateTrackingInfo() {
	userInfo = {};

	lsUserInfo.eachEntry(function (k, v) {
		userInfo[k] = v;
	});

	var params = new URLSearchParams(new URL(window.location).search);
	for (let [k, v] of params) {

		if (oneIs(k, 'ref_channel', 'ref_channelid', 'ref_campaignid')) {
			setUserInfo(k, v);
		}

	}

	if (!userInfo.vid) {
		setUserInfo("vid", guid());
	}

	if (VOFFICE.channelid) {
		userInfo.ref_channelid = VOFFICE.channelid;
	}


}

updateTrackingInfo();




export {
	saveSelection,
	hasCookieChoice,
	isCookiesOk,
	fireQuote,
	fireCustomEvent,
	fireFormSubmit,
	fireUnitAction,
	fireCheckout,
	fireBooking,
	fireOptionBooked,
	fireSearch,
	fireNewsletterSignup,
	getUserInfo
};