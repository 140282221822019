var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "panel panel-default" }, [
    _c("div", { staticClass: "panel-heading" }, [
      _c("h4", { staticClass: "panel-title" }, [
        _c(
          "a",
          {
            attrs: {
              "data-toggle": "collapse",
              "data-parent": _vm.wrapper ? "#" + _vm.wrapper : "#accordion",
              href: "#" + _vm.getId,
              "aria-expanded": "true"
            }
          },
          [_vm._v("\n     " + _vm._s(_vm.getQuestionText) + "\n     ")]
        )
      ])
    ]),
    _vm._v(" "),
    _c(
      "div",
      { staticClass: "panel-collapse collapse in", attrs: { id: _vm.getId } },
      [
        _c("div", { staticClass: "panel-body" }, [
          _c("p", [
            _vm._v(
              "\n                " +
                _vm._s(_vm.code ? _vm.voMsg(_vm.answer) : _vm.answer) +
                "\n            "
            )
          ])
        ])
      ]
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }