<template>
    <div class="ratings"
        v-if="fullstars > 3">
        <i v-for="(n, index) in fullstars"
            class="fa fa-star"></i>
        <i v-for="(n, index) in emptystars"
            class="fa fa-star-o"></i>
        <!--<span v-if="fullstars==4">Gut</span>
        <span v-if="fullstars==5">Sehr Gut</span>-->
    </div>
</template>
<script>
    import V from 'voUtils/V.js';
    import DateEx from 'voUtils/DateEx.js';

    export default {
    	voVueComponent: 'star-display',
    	props: {
    		stars: Number
    	},
    	data: function() {
    		return {
    			totalStars: 5
    		};
    	},
    	computed: {
    		fullstars: function() {
    			return this.stars || 0;
    		},
    		emptystars: function() {
    			return this.totalStars - (this.stars || 0);
    		}
    	}
    };
</script>