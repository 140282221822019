<template>
    <span>{{addedvalue}}</span>

</template>
<script>
    import V from 'voUtils/V.js';
    import DateEx from 'voUtils/DateEx.js';

    export default {
    	voVueComponent: 'increase-number',
    	props: {
    		originalnumber: Number
    	},
    	computed: {
    		addedvalue: function() {
    			return this.originalnumber + 1;
    		}
    	}
    };
</script>