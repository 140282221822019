import {
	getStorage
}
from './StorageEx.js';

const lSettings = getStorage('favorites');

var favorites = new Set(lSettings.getIds('selected', []));

var listeners = [];



function update() {
	lSettings.setIds("selected", Array.from(favorites));
	for (let l of listeners) {
		l(favorites);
	}
}

export function onFavUpdate(listener) {
	listeners.push(listener);
}

export function addFav(id) {
	favorites.add(id);
	update();
}

export function removeFav(id) {
	favorites.delete(id);
	update();
}
export function toggleFav(id) {
	if (favorites.has(id)) {
		removeFav(id);
	} else {
		addFav(id);
	}
}

export function isFav(id) {
	return favorites.has(id);
}

export function getFavIds() {
	return Array.from(favorites);
}

export function favCount() {
	return favorites.size;
}