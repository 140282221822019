import V from 'voUtils/V.js';

import {
	getStorage
}
from './StorageEx.js';
import {
	randomInt
}
from './tools.js';
const lSettings = getStorage('abtest');

var abtest;

if (VOFFICE.abtestPossibilities) {
	abtest = lSettings.getInt('val', randomInt(1, VOFFICE.abtestPossibilities));
	V.log("VOFFICE.abtest", abtest);

	lSettings.setInt('val', abtest);

	if (VOFFICE.abtestSettings && VOFFICE.abtestSettings[abtest]) {
		Object.assign(VOFFICE, VOFFICE.abtestSettings[abtest]);
	}

}

export function isAbTest(val) {
	return VOFFICE.abtest == (val || 2);
}
export function getAbTest(val) {
	return abtest;
}