import V from 'voUtils/V.js';
import {
	isNothing
}
from 'voUtils/tools.js';

import Vue from 'vue';

function formatMsg(msg, args) {
	if (msg && args) {
		return msg.replace(/\{(\d+)\}/g, function () {
			return args[arguments[1]];
		});
	} else {
		return msg || '';
	}
}

function voMsg(code, args) {
	var msg = window.VOFFICE.codes && window.VOFFICE.codes[code];
	return formatMsg(msg, args);
}

function voMsgPl(code, amount, args) {
	var msg = window.VOFFICE.codes && window.VOFFICE.codes[code];
	if (msg) {
		msg = msg.split('|');
		msg = (amount == 1 || msg.length < 2) ? msg[0] : msg[1];
	}

	return formatMsg(msg, args);
}

Vue.filter("vcode", function (val, prefix) {
	return voMsg(prefix + '.' + val);
});




var intlNumber = {};
if (typeof Intl != undefined && typeof Intl.NumberFormat != undefined) {
	intlNumber.currency = new Intl.NumberFormat(VOFFICE.lang, {
		style: 'currency',
		currency: VOFFICE.currency || 'EUR'
	});
	intlNumber.roundedCurrency = new Intl.NumberFormat(VOFFICE.lang, {
		style: 'currency',
		minimumFractionDigits: 0,
		maximumFractionDigits: 0,
		currency: VOFFICE.currency || 'EUR'
	});
	intlNumber.distance = new Intl.NumberFormat(VOFFICE.lang, {
		style: 'decimal',
		minimumFractionDigits: 0,
		maximumFractionDigits: 1,
	});
	intlNumber.integer = new Intl.NumberFormat(VOFFICE.lang, {
		style: 'decimal'
	});
}

function voFormatNumber(val, format) {
	if (!isNothing(val)) {

		format = format || 'integer';
		if (format.toLowerCase().indexOf('currency') != -1) {
			val = (val) / 100;
		}
		if (format == 'currencyNoZeroFraction') {
			if (Math.round(val) == val) {
				format = 'roundedCurrency';
			} else {
				format = 'currency';
			}
		}

		return intlNumber[format || 'default'].format(val);
	} else {
		return "";
	}
}


Vue.filter("vcurrency", function (val, format) {
	return voFormatNumber(val, format || 'currency');
});

Vue.filter("vautokm", function (val, format) {
	if (typeof val === "number") {
		if (val >= 1000) {
			return voFormatNumber(val / 1000, 'distance') + ' km';
		} else {
			return val + ' m';
		}
	}

	return '';


});


export {
	formatMsg,
	voMsg,
	voMsgPl
};