import V from 'voUtils/V.js';
import {
	hash, guid
}
from 'voUtils/tools.js';

window.vo_jsons = {};

function jsonp(o) {
	var script = document.createElement('script');

	const paramString = new URLSearchParams(o.send).toString();

	var callbackName = 'vo_jsonp_callback_' + hash(paramString); //+ Math.round(100000 * Math.random());
	if (!window[callbackName]) {
		window[callbackName] = function (data) {
			let cb;
			while ((cb = window.vo_jsons[callbackName].pop())) {
				cb(data);
			}
			delete window[callbackName];
			delete window.vo_jsons[callbackName];
		};
	}

	if (!window.vo_jsons[callbackName]) {
		window.vo_jsons[callbackName] = [];
	}

	window.vo_jsons[callbackName].push(function (data) {
		document.body.removeChild(script);
		o.success(data);
	});

	//o.send.callback = callbackName;

	script.src = o.url + '?' + paramString + '&callback=' + callbackName;
	if (o.error) {
		script.onerror = o.error;
	}
	document.body.appendChild(script);
}



export function doPost(actionName, data, o = {}) {
	o.post = true;
	//o.url = o.url || 'https://dev.v-office.com/api/json/' + actionName;
	return doReq(actionName, data, o);
}

export function doReq(actionName, data, o = {}) {

	var send = {
		actionName: actionName,
		lang: VOFFICE.lang,
		token: VOFFICE.token,
		data: JSON.stringify(data)
	};
	if (o.params) {
		Object.assign(send, o.params);
	}

	if (o.noCache) {
		send._guid = guid();
	}

	var url = o.url || ('https://' + (VOFFICE.devrest ? VOFFICE.devrest : 'api2') + '.v-office.com/api/json/' + actionName);

	if ('fetch' in window) {
		url = new URL(url);
		let fetchParams = {
			method: o.post ? 'POST' : 'GET',
			mode: 'cors',
		};
		if (o.post) {
			delete send.data;
			fetchParams.headers = {
				'Accept': 'application/json',
				'Content-Type': 'application/json'
			};
			fetchParams.body = JSON.stringify(data);
		}

		Object.keys(send).forEach(key => url.searchParams.append(key, send[key]));

		return fetch(url, fetchParams)
			.then(response => response.json())
			.then(res => {
				if (res.ok) {
					return res;
				} else {
					return Promise.reject(res);
				}
			});


	} else {
		V.log("JSONP", actionName, data);

		return new Promise(function (resolve, reject) {
			jsonp({
				noCache: o.noCache,
				url: url,
				send: send,
				success: function (res) {
					if (res.ok) {
						resolve(res);
					} else {
						reject(res);
					}
				},
				error: function (e) {
					reject({
						loadError: e
					});
				}
			});

		});
	}






}