import V from 'voUtils/V.js';

import {
	getStorage
}
from './StorageEx.js';
import DateEx from 'voUtils/DateEx.js';

const lSettings = getStorage('voucher');


var validated = lSettings.get('validated');
var voucher;
if (validated && DateEx.toDate(validated) < DateEx.getTomorrow()) {
	voucher = {
		code: lSettings.get('code'),
		name: lSettings.get('name')
	};
}

export function getVoucher() {
	return voucher;
}

export function setVoucher(_voucher) {
	voucher = _voucher;
	lSettings.set('code', _voucher.code);
	lSettings.set('name', _voucher.name);
	lSettings.set('validated', DateEx.interfaceFormat(DateEx.getToday()));

}