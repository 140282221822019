<template>
    <form class="hidden-print contact-form voucher-form"
        @submit.prevent="submit">


        <div class="form-horizontal">

            <div class="alert alert-info"
                v-show="status=='done'">
                <button type="button"
                    class="close"
                    aria-hidden="true"
                    @click="status='ready'">&times;</button>

                <span>{{voMsg('voucher.success',[voucher && voucher.code])}}</span>
            </div>

            <div class="alert alert-warning"
                v-show="status=='failed'">
                <span>{{voMsg('tpl.voucherfailed')}}</span>
            </div>



            <div v-show="status=='ready' || status=='failed'">

                <div class="form-group row">
                    <div class="col-sm-12">
                        <input type="text"
                            class="form-control"
                            id="input_code_voucher"
                            v-model="reqCode"
                            required="required">
                    </div>
                </div>
                <button type="submit"
                    class="btn btn-go btn-block">
                    {{submitLabel}}
                </button>

            </div>

            <div class="contact-sending"
                v-show="status=='submitting'"
                ng-cloak>
                <div class="spinner">
                    <div class="bounce1"></div>
                    <div class="bounce2"></div>
                    <div class="bounce3"></div>
                </div>

            </div>

            <voffice-error-message :error="error"></voffice-error-message>


        </div>
    </form>

</template>


<script>
    import V from 'voUtils/V.js'

    import {
    	doPost
    }
    from 'voUtils/RestEx.js';

    import {
    	formatMsg
    }
    from 'voUtils/voI18n.js'

    import {
    	setVoucher, getVoucher
    }
    from 'voUtils/VoucherStore.js'

    var voucher = getVoucher();

    export default {
    	voVueComponent: 'voffice-voucher',
    	props: {
    		voucherOkMsg: String,
    		voucherFailedMsg: String,
    		voucherCodeLabel: String,
    		submitLabel: String
    	},
    	data() {
    		return {
    			error: undefined,
    			reqCode: undefined,
    			status: voucher ? 'done' : 'ready',
    			voucher: voucher
    		}
    	},
    	methods: {
    		submit: function() {

    			this.status = 'submitting';

    			var req = {
    				code: this.reqCode
    			};

    			voucher = undefined;

    			doPost('validateVoucher', req)
    				.then(res => {

    					if (res.voucher) {
    						res.voucher.code = req.code;

    						this.voucher = res.voucher;
    						setVoucher(this.voucher);

    						this.status = 'done';


    					} else {
    						this.status = 'failed';
    					}


    				})
    				.catch(e => {
    					this.status = 'ready';
    					this.error = e;
    				})


    		}
    	}

    };
</script>
<style scoped>
    .voucher-form {
    	max-width: 600px;
    }
</style>