import V from 'voUtils/V.js';

import Vue from 'vue';

import {
	differenceInDays,
	startOfDay,
	startOfToday,
	startOfTomorrow,
	addDays,
	parseISO,
	format
}
from 'base/date-fns-v2';


function getToday() {
	return startOfToday();
}

function getTomorrow() {
	return startOfTomorrow();
}

function toDate(d) {
	if (typeof d == 'string') {
		return parseISO(d);
	} else {
		return d;
	}
}

const DateRegEx = /^\d{4}-([0]\d|1[0-2])-([0-2]\d|3[01])$/;
const LocalDateRegEx = /^\d{4}-d{2}-d{2}$/;

function isDate(d) {
	return (d instanceof Date || (typeof d == 'string' && LocalDateRegEx.test(d)));
}

function localDate(d) {
	return startOfDay(toDate(d));
}

function interfaceFormat(d) {
	return d ? format(toDate(d), 'yyyy-MM-dd') : d;
}

function getDays(a, b) {
	return differenceInDays(startOfDay(toDate(a)), startOfDay(toDate(b)));
}

function plusDays(a, b) {
	return addDays(startOfDay(toDate(a)), b);
}


var intlDate = {
	default: {
		year: '2-digit',
		month: '2-digit',
		day: '2-digit'
	},
	withDay: {
		weekday: 'short',
		year: '2-digit',
		month: 'numeric',
		day: 'numeric'
	},
	fullDay: {
		weekday: 'long',
		year: '2-digit',
		month: 'numeric',
		day: 'numeric'
	}
};

var intlDateFunctions = {};

function getDateFormat(format, lang) {
	var key = format + ':' + lang;
	var f = intlDateFunctions[key];
	if (!f) {
		V.log("create date format", key);
		if (typeof Intl != undefined && typeof Intl.DateTimeFormat != undefined) {
			f = new Intl.DateTimeFormat(lang, intlDate[format] || intlDate.default); //VOFFICE.lang
		} else {
			f = {
				format: function (d) {
					return format(d, 'dd.MM.yy');
				}
			};
		}
		intlDateFunctions[key] = f;
	}
	return f;
}


function formatDate(val, format, lang) {
	if (val) {
		if (typeof val == 'string') {
			val = parseISO(val);
		}
		return getDateFormat(format, lang).format(val);
	} else {
		return "";
	}
}

function formatDates(o, format, lang) {
	if (o) {
		for (let k in o) {
			if (isDate(o[k])) {
				o[k] = formatDate(o[k], format, lang);
			}
		}
	}
	return o;
}

function encodeDates(o) {
	if (o) {
		for (let k in o) {
			if (isDate(o[k])) {
				o[k] = interfaceFormat(o[k]);
			}
		}
	}
	return o;
}


Vue.filter("vdate", function (val, format) {
	return formatDate(val, format);
});




export default {
	formatDate,
	formatDates,
	encodeDates,
	getToday,
	localDate,
	interfaceFormat,
	getDays,
	toDate,
	isDate,
	getTomorrow,
	plusDays
};