<template>
    <div class="panel panel-default">
        <div class="panel-heading">
            <h4 class="panel-title">
         <a data-toggle="collapse" :data-parent="wrapper ? `#${wrapper}` : '#accordion' "  :href="'#'+getId" aria-expanded="true" >
         {{getQuestionText}}
         </a>
      </h4>
        </div>
        <div :id="getId"
            class="panel-collapse collapse in">
            <div class="panel-body">
                <p>
                    {{code ? voMsg(answer) : answer}}
                </p>
            </div>
        </div>
    </div>
</template>
<script>
    import V from 'voUtils/V.js'

    export default {
    	voVueComponent: 'voffice-accordion',
    	props: {
    		question: String,
    		answer: String,
    		code: Boolean,
    		wrapper: String,
    	},
    	data() {
    		return {}
    	},
    	computed: {
    		getQuestionText: function() {
    			//console.log('this.voMsg('faq.question'))
    			if (this.code) {
    				return this.voMsg(this.question);
    			} else {
    				return this.question;
    			}
    		},
    		getId: function() {
    			return 'collapse' + [...Array(9)].map(() => Math.random().toString(36)[2]).join('')
    		}
    	},
    	methods: {}
    };
</script>

<style scoped>
</style>