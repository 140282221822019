var head = document.getElementsByTagName('head')[0];

function getSrc(lib) {
	var el, src;
	if (lib.id) {
		el = document.getElementById(lib.id);
		if (el) {
			src = el.getAttribute("content");
			if (src && src.length) {
				return src;
			}

		}
	}
	return lib.src || lib.href;
}


function addJs(lib, doNext) {

	var s = document.createElement('script');

	//s.type = "text/javascript";
	s.async = lib.async;

	var _libLoaded = function (ev, notreally) {
		libLoaded(lib, notreally);
		if (!lib.async) {
			doNext();
		}
	};

	var src = getSrc(lib);

	if (src) {

		if (s.addEventListener) {
			s.addEventListener('load', _libLoaded);
		} else if (s.attachEvent) {
			s.attachEvent('onload', _libLoaded);
		} else {
			setTimeout(_libLoaded, 1000);
		}

		s.src = src;
		head.appendChild(s);

	} else {
		_libLoaded(null, true);
	}

	if (lib.async) {
		doNext();
	}


}


function addCss(lib, doNext) {
	var link = document.createElement('link');

	link.rel = 'stylesheet';
	var src = getSrc(lib);

	if (src) {

		link.href = src;

		head.appendChild(link);
		libLoaded(lib);
		doNext();
	} else {
		libLoaded(lib, true);
		doNext();
	}
}


var requested = {};

function libLoaded(lib, notreally) {
	lib.loaded = true;

	for (let onDone of requested[lib.key].onDone) {
		onDone(lib);
	}
}

function requireLibs(libs) {
	var allLoaded;

	var libCount = libs.length;
	var loadCount = 0;

	function _libLoaded() {
		loadCount++;
		if (libCount === loadCount && allLoaded) {
			allLoaded();
		}
	}

	function addNextLib() {
		var lib;
		if (libs.length > 0) {
			lib = libs.shift();

			if (typeof lib == 'string') {
				lib = {
					id: lib
				};
			}
			lib.key = lib.id || lib.href || lib.src;

			if (!lib.type) {
				lib.type = lib.key.endsWith('.js') ? 'JS' : 'CSS';
			}
			lib.async = lib.async !== false;

			if (requested[lib.key]) {
				if (requested[lib.key].lib.loaded) {
					_libLoaded();
					addNextLib();
				} else {
					requested[lib.key].onDone.push(function (_lib) {
						_libLoaded();
						if (!lib.async) {
							addNextLib();
						}
					});

					if (lib.async) {
						addNextLib();
					}


				}
			} else {
				requested[lib.key] = {
					lib: lib,
					onDone: [_libLoaded]
				};

				if (lib.type == 'JS') {
					addJs(lib, addNextLib);
				} else {
					addCss(lib, addNextLib);
				}

			}

		}
	}


	addNextLib();



	return function (done) {
		allLoaded = done;
		if (libCount === loadCount) {
			done();
		}
	};

}


var gMapsListener = [];

function loadGmaps(done) {
	if (gMapsListener) {
		gMapsListener.push(done);

		if (!window.gmapsInitialized) {

			window.gmapsInitialized = function () {
				//google.maps.visualRefresh = true;
				for (let l of gMapsListener) {
					l();
				}
				gMapsListener = undefined;
			};
			var script = document.createElement("script");
			script.type = "text/javascript";
			script.src = "https://maps.google.com/maps/api/js?v=3&callback=gmapsInitialized";
			if (VOFFICE.gmapsKey) {
				script.src += '&key=' + VOFFICE.gmapsKey;
			} else {
				script.src += '&key=' + 'AIzaSyD5xtaHvX2AjG7Iib8iDo4wzhGlepwWFco';
			}

			document.body.appendChild(script);
		}

	} else {
		done();
	}



}




export {
	requireLibs,
	loadGmaps
};