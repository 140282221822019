var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      directives: [
        {
          name: "show",
          rawName: "v-show",
          value: _vm.error,
          expression: "error"
        }
      ],
      staticClass: "alert alert-danger",
      attrs: { role: "alert" }
    },
    [_vm._v("\n\n    " + _vm._s(_vm.reasonText) + "\n\n")]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }