<template>
    <div class="ratings"
        v-if="fullstars > 0">
        <i v-for="(n, index) in fullstars"
            :title="titletext"
            data-toggle="tooltip"
            class="fa fa-star"></i>
        <i v-if="halfstar"
            :title="titletext"
            data-toggle="tooltip"
            class="fa fa-star-half-alt"></i>
        <i v-for="(n, index) in emptystars"
            :title="titletext"
            data-toggle="tooltip"
            class="fa fa-star-o"></i>
    </div>
</template>
<script>
    import V from 'voUtils/V.js';
    import DateEx from 'voUtils/DateEx.js';

    export default {
    	voVueComponent: 'star-rating',
    	props: {
    		stars: {
    			type: [Number, String]
    		},
    		titletext: String
    	},
    	data: function() {
    		return {
    			totalStars: 5,
    			precision: 0.5
    		};
    	},
    	mounted: function() {
    		$('[data-toggle="tooltip"]').tooltip();
    		$('[data-toggle="popover"]').popover();
    	},
    	computed: {
    		fullstars: function() {
    			var round = Math.round(this.stars / this.precision) * this.precision;
    			var fullStars = Math.floor(round);
    			return fullStars || 0;
    		},
    		halfstar: function() {
    			var round = Math.round(this.stars / this.precision) * this.precision;
    			var fullStars = Math.floor(round);
    			return (round > fullStars ? true : false);
    		},
    		emptystars: function() {
    			if (this.halfstar) {
    				return this.totalStars - (parseInt(this.stars) || 0) - 1;
    			} else {
    				if (Math.round(this.stars) > parseInt(this.stars)) {
    					return this.totalStars - (parseInt(this.stars) || 0) - 1;
    				} else {
    					return this.totalStars - (parseInt(this.stars) || 0);
    				}

    			}
    		}
    	}
    };
</script>