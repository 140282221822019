function supports_html5_storage() {
	try {
		return 'localStorage' in window && window.localStorage !== null;
	} catch (e) {
		return false;
	}
}

var isSupported = supports_html5_storage();

function getStorage(pre) {

	function getKey(key) {
		return pre + '_' + key;
	}

	var removeIt = function (key) {
		if (isSupported) {
			localStorage.removeItem(getKey(key));
		}
	};

	var getIt = function (key, def) {
		if (isSupported) {
			var r = localStorage.getItem(getKey(key));
			if (r == undefined || r == null) {
				return def;
			} else {
				return r;
			}

		} else {
			return def;
		}
	};
	var setIt = function (key, val) {
		if (isSupported) {
			localStorage.setItem(getKey(key), val);
		}
	};


	var getBool = function (key, def) {
		var r = getIt(key, def);
		if (r === 'true') {
			return true;
		} else if (r === 'false') {
			return false;
		} else {
			return r;
		}
	};

	var setBool = function (key, val) {
		setIt(key, val ? 'true' : 'false');
	};

	var getInt = function (key, def) {
		var r = getIt(key, def);
		if (typeof r == "number") {
			return r;
		} else {
			return parseInt(r, 10);
		}
	};

	var setInt = function (key, val) {
		setIt(key, '' + val);
	};


	var getIds = function (key, def) {
		var r = getIt(key, null);
		if (r) {
			return r.split(',').map((id) => {
				return parseInt(id, 10);
			});
		} else {
			return def;
		}
	};

	var setIds = function (key, values) {
		if (values && values.length) {
			var val = values.join(',');
			setIt(key, val);
		} else {
			removeIt(key);
		}
	};



	var eachEntry = function (go) {
		var k;
		for (var i = 0, len = localStorage.length; i < len; ++i) {
			k = localStorage.key(i);
			if (k.startsWith(pre + "_")) {
				k = k.substring(pre.length + 1);
				go(k, getIt(k));
			}

		}
	};



	return {
		get: getIt,
		set: setIt,
		setIds: setIds,
		getIds: getIds,
		remove: removeIt,
		getBool: getBool,
		setBool: setBool,
		getInt: getInt,
		setInt: setInt,
		eachEntry: eachEntry
	};

}

export {
	getStorage
};