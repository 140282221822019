import V from 'voUtils/V.js';
import {
	requireLibs
}
from 'voUtils/loader.js';


export default {
	voVueComponent: 'voffice-photoswipe',
	template: "<div><slot></slot></div>",
	props: {
		items: Array,
		index: Number
	},
	created: function () {
		this.onLoaded = requireLibs([
			"photoswipe.css",
			"photoswipe-skin.css", {
				id: "photoswipe.js",
				async: false
			},
			"photoswipeUI.js",
		]);
	},
	methods: {
		showGallery: function () {

			this.hideGallery();

			var options = {
				index: this.index || 0
			};

			this.onLoaded(() => {
				this.gallery = new PhotoSwipe(this.$el.firstChild, PhotoSwipeUI_Default, this.items, options);
				this.gallery.init();
				this.gallery.listen('close', () => {
					this.gallery = undefined;
					this.$emit('update:index', undefined);
				});

			});

		},
		hideGallery: function () {

			if (this.gallery) {
				this.gallery.close();
				//this.gallery.destroy();
				this.gallery = undefined;
			}

		}
	},
	watch: {
		index: function (nv) {
			if (typeof this.index == 'number') {
				this.showGallery();
			} else {
				this.hideGallery();
			}
		}
	}

};