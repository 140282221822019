import V from 'voUtils/V.js';


export function capitalize(string) {
	return string.charAt(0).toUpperCase() + string.slice(1).toLowerCase();
}
export function capFirst(string) {
	return string.charAt(0).toUpperCase() + string.slice(1);
}

export function isBlank(str) {
	if (!str || (typeof str == 'string' && !str.trim().length)) {
		return true;
	} else {
		return false;
	}
}
export function isNothing(val) {
	return val == undefined || val == null || val == NaN;
}

export function isEmpty(val) {
	if (val && typeof val == 'object' && !Array.isArray(val)) {
		val = Object.keys(val);
	}
	return (!val || !val.length);
}

export function debounce(func, wait, immediate) {
	var timeout;
	return function () {
		var context = this,
			args = arguments;
		var later = function () {
			timeout = null;
			if (!immediate) func.apply(context, args);
		};
		var callNow = immediate && !timeout;
		clearTimeout(timeout);
		timeout = setTimeout(later, wait);
		if (callNow) func.apply(context, args);
	};
}

export function find(list, test) {

	for (let e of list) {
		if (test(e)) {
			return e;
		}
	}
	return null;

}

export function filter(list, test) {
	var res = [];
	for (let e of list) {
		if (test(e)) {
			res.push(e);
		}
	}
	return res;

}
export function sumOf(list, go) {
	if (list) {
		return list.reduce((sum, val) => {
			return sum += typeof go == 'string' ? val[go] : go(val);
		}, 0);
	} else {
		return false;
	}
}

export function randomInt(min = 0, max = 100) {
	return Math.floor(Math.random() * (max + 1 - min)) + min;
}


export function pick() {
	var res = {};
	var o = arguments[0];
	for (var i = 1; i < arguments.length; i++) {
		let k = arguments[i];
		if (!isNothing(o[k])) {
			res[k] = o[k];
		}
	}
	return res;
}

export function omit() {
	var o = arguments[0];
	var ex = new Set(Array.from(arguments).slice(1));

	var res = {};
	for (let k of Object.keys(o)) {
		if (!isNothing(o[k]) && !ex.has(k)) {
			res[k] = o[k];
		}
	}

	return res;

}

export function param(obj) {
	return Object.entries(obj).map(([key, val]) => `${key}=${val}`).join('&');
}

export function removeKey(o, name) {
	var val = o[name];
	delete o[name];
	return val;
}

export function oneIs() {

	for (var i = 1; i < arguments.length; i++) {
		if (arguments[0] === arguments[i]) {
			return true;
		}
	}

	return false;
}

export function each(val, go) {
	if (val) {
		let i = 0;
		if (Array.isArray(val)) {
			for (let e of val) {
				go(e, i++);
			}
		} else {
			for (let k of Object.keys(val)) {
				go(val[k], k, i++);
			}
		}
	}
}

export function hash(str) {

	var hash = 5381,
		i = str.length;

	while (i) {
		hash = (hash * 33) ^ str.charCodeAt(--i);
	}

	return hash >>> 0;

}

export function guid() {


	var d = new Date().getTime();
	if (typeof performance !== 'undefined' && typeof performance.now === 'function') {
		d += performance.now(); //use high-precision timer if available
	}
	return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
		var r = (d + Math.random() * 16) % 16 | 0;
		d = Math.floor(d / 16);
		return (c === 'x' ? r : (r & 0x3 | 0x8)).toString(16);
	});


}