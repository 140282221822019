export default {
	data: {
		selectedImageIndex: undefined
	},
	beforeMount: function () {
		//console.warn("ha", window.location.search);
		var url = new URL(window.location.href);
		var params = {};
		for (let key of url.searchParams.keys()) {
			//console.log("p", key, url.searchParams.get(key));
			params[key] = url.searchParams.get(key);

		}
		this.searchParams = params;
	},
	mounted: function () {
		var currentUrl = window.location.href;
		if (currentUrl.indexOf('#') >= 0) {
			var section = currentUrl.substring(currentUrl.indexOf('#') + 1);
			if (section) {
				$('html, body').animate({
					scrollTop: $("#" + section).offset().top - 150
				}, 300);
			}
		}
	},
	computed: {

		mailto: function () {
			return `mailto:${this.emailAddress}`;
		},
		emailAddress: function () {
			return (VOFFICE.contactEmail || ['info', 'test.de']).join('@');
		}
	}

};