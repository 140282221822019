<template>
    <div class="member">

        <img class="member-image"
            :v-if="dataSrc"
            :src="dataSrc" />
        <div class="member-info">
            <div class="name">
                {{name}}
            </div>
            <div class="resort">
                {{resort}}
            </div>
            <div class="phone">
                {{phone}}
            </div>
            <div class="email">
                {{email}}
            </div>
        </div>
    </div>
</template>

<script>
    import V from 'voUtils/V.js'
    export default {
    	voVueComponent: 'voffice-team-member',
    	data() {
    		return {}
    	},
    	props: {
    		name: String,
    		phone: String,
    		email: String,
    		image: String,
    		resort: String,
    		dataSrc: String,
    	},
    	computed: {},

    	methods: {},
    }
</script>



<style scoped>
    .member {
    	display: flex;
    	flex-direction: column;
    	border: 1px solid #ddd;
    	border-radius: 4px;
    }

    .member-image {
    	width: 100%;
    	height: auto;
    	display: block;
    	margin: 0 auto;
    	max-height: 340px;
    	object-fit: cover;
    	object-position: top;

    }

    .member-info {
    	display: flex;
    	flex-direction: column;
    	justify-content: center;
    	min-height: 140px;
    	padding: 0 1em;
    }
</style>