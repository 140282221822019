<template>
    <div v-show="error"
        class="alert alert-danger"
        role="alert">

        {{reasonText}}

    </div>

</template>

<script>
    import V from 'voUtils/V.js'

    export default {
    	voVueComponent: 'voffice-error-message',
    	props: {
    		error: Object
    	},
    	data() {
    		return {}
    	},
    	computed: {
    		reasonText: function() {
    			if (this.error) {
    				V.logError("error reason", this.error);
    			}
    			return (this.error && this.error.reasonText) || this.voMsg('submit.failed');
    		}
    	}


    };
</script>