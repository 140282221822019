if (!"".matchAll) {
	String.prototype.matchAll = function (pattern) {
		if (pattern.toString().substr(-1) == 'g') {
			// Clear 'g' flag, if set
			var patternStr = pattern.toString();
			pattern = patternStr.substr(1, patternStr.length - 3);
		}
		var matches = [];
		var match_result = this.match(new RegExp(pattern, 'g'));
		for (let index in match_result) {
			var item = match_result[index];
			matches[index] = item.match(new RegExp(pattern));
		}
		return matches;
	};
}



export function showElement(el) {
	el.style.display = "block";
}
export function hideElement(el) {
	el.style.display = "none";
}

export function findPreviousSiblingByName(el, name) {
	while (el.previousSibling) {
		el = el.previousSibling;
		if (el && el.nodeName == 'label') {
			return el;
		}
	}

	return null;

}


export function findResultFields(html, el) {


	let fields = {};
	let addFields = function (expr) {
		let parts = expr.split('|');
		let field = {
			field: parts[0].trim()
		};
		if (parts.length > 1) {
			let filterMatch = parts[1].trim().match(/(\w+) *\( *'([\w\.]+)' *\)/);
			if (filterMatch) {
				field.filter = filterMatch[1];
				field.filterparams = [filterMatch[2]];
			} else {
				field.filter = parts[1].trim();
			}
		}
		fields['u.' + field.field] = field;
	};


	let matches = html.matchAll(/:[\w- ]+= *" *u.([\w \.\|'\)\(]+) *"/g);
	for (let m of matches) {
		addFields(m[1]);
	}
	matches = html.matchAll(/v-[\w- ]+= *" *u.([\w \.\|'\)\(]+) *"/g);
	for (let m of matches) {
		addFields(m[1]);
	}

	matches = html.matchAll(/\{\{ *u.([\w \.\|'\)\(]+) *\}\}/g);
	for (let m of matches) {
		addFields(m[1]);
	}

	if (el) {
		el.querySelectorAll('[data-add-result-fields]').forEach((el) => {
			var addResfields = el.getAttribute('data-add-result-fields').split(',');
			for (let f of addResfields) {
				addFields(f);
			}

		});
	}

	return fields;


}

export function getRootUrl() {
	var url = 'https://' + window.location.host;
	var base = document.getElementsByTagName('base')[0];
	if (base) {
		url += base.getAttribute("href");
	}
	return url;
}